import React, { useEffect, useState } from "react";

import { Box, Checkbox, Collapse, FormControlLabel, Grid, TextField } from "@mui/material";
import ReactPasswordChecklist from "react-password-checklist";
import { IUser } from "../../interfaces/IUser";

interface IProps {
    userObject: IUser;
    setUserObject: Function;
    setIsValid?: Function;
    onBlur?: Function;
    required?:boolean;
}


export const UserSetPassword:React.FC<IProps> = (props) => {
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [isValid, setIsValid] = useState(false);
    //const [genNewPW,setGenNewPW] = useState((props.userObject.GenerateNewPassword !== undefined) ? props.userObject.GenerateNewPassword : false);

    useEffect(() => {
        (props.setIsValid !== undefined) && props.setIsValid(isValid);
    },[isValid])

    useEffect( () => {
        if (password === "") {
            props.setUserObject({...props.userObject, password: undefined})
        } else {
            props.setUserObject({...props.userObject, password: password})
        }
    },[password])


    return(
        <>
            <Box sx={{mt: 5}}/>
                <Grid item sm={6}>
                        <TextField
                            type="password"
                            label="Password"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { console.log("lel"); setPassword( event.target.value )}}
                            fullWidth
                            required={(props.required !== undefined && props.required === true)}
                            onBlur={(props.onBlur === undefined) ? undefined : () => props.onBlur!(password)}
                            autoFocus={(props.onBlur !== undefined)}
                        />
                </Grid>

                
                <Grid item sm={6}>
                        <TextField
                            type="password"
                            label="Repeat password"
                            value={passwordAgain}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordAgain( event.target.value )}
                            fullWidth
                            required={(props.required !== undefined && props.required === true)}
                        />
                </Grid>

                <Grid item sm={12}>
                        <ReactPasswordChecklist
                            //rules={["minLength","specialChar","number","capital","match"]}
                            rules={["minLength","match"]}
                            minLength={12}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => setIsValid(isValid)}
                        />
                </Grid>
                
        </>
    )
}