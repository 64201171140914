import React, { useState } from "react";
import { IUser } from "../../../interfaces/IUser";
import { GridColDef } from "@mui/x-data-grid";
import { IObjectTableCol, ObjectTable } from "../../core/ObjectTable";
import { UserEdit } from "../../User/UserEdit";
import { getIsAdmin } from "../../core/helper";

interface IProps {
    userArray: IUser[];
    setUserArray: Function;
    userIdArray: number[];
    setUserIdArray: Function;
}

const getEmptyUser = () => {
    return {
        first_name: "",
        id: -1,
        is_superuser: false,
        last_name: "",
        username: ""
    } as IUser
}



export const UserTable:React.FC<IProps> = ({userArray, userIdArray, setUserArray, setUserIdArray}) => {
    const isAdmin = getIsAdmin();
    const [editUser,setEditUser] = useState(getEmptyUser());


    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'username', 
            headerName: 'Benutzer', 
            flex: 1
        },
    ]

    const tableCol:IObjectTableCol[] = [
        {
            title: "Nr.",
            attr: "id"
        },
        {
            title: "Benuter",
            attr: "username"
        }

    ]


    return(
        <>
            <ObjectTable
                columns={columns}
                objectTableCol={tableCol}
                title="Benutzer"
                objectArray={userArray}
                selectedId={userIdArray}
                setSelectedId={setUserIdArray}
                //
                setObjectArray={setUserArray}
                editObject={editUser}
                setEditObject={setEditUser}
                getNewObject={getEmptyUser}
                uploadUrl="/user/"
                //
                disableBtns={!isAdmin}
            >
                <UserEdit
                    userEdit={editUser}
                    setUserEdit={setEditUser}
                />
            </ObjectTable>
        </>
    )
}