import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { IGender } from "../interfaces/IGender";
import { IAcademicTitle } from "../interfaces/IAcademicTitle";
import { IApp } from "../interfaces/IApp";
import { IModule } from "../interfaces/IModule";
import { IScheduling } from "../interfaces/IScheduling";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ICompany } from "../interfaces/ICompany";
import { GenericOverview } from "../components/core/GenericOverview";
import { CompanyEdit } from "../components/Customer/CompanyEdit";
import { IUser } from "../interfaces/IUser";
import { ICustomer } from "../interfaces/ICustomer";
import { getIsAdmin } from "../components/core/helper";
import { ISubscription } from "../interfaces/ISubscription";
import { IContract } from "../interfaces/IContract";
import { IServerType } from "../interfaces/IServerType";
import { IServer } from "../interfaces/IServer";


const getEmptyCompany = () => {
    return {
        id: -1,
        ci_color_primary: "#ffffff",
        ci_color_secondary: "#ffffff",
        ci_color_text: "#ffffff",
        company_name: "",
        Contracts: [],
        customer_number_lextorbyte: "",
        customer_number_kemto: "",
        email: "",
        homepage: "",
        logo: "",
        logo_mime: "",
        favicon: "",
        phone_number: "",
        Postcode: 1,
        street: "",
        street_nr: "",
        tax_number: "",
        Users: [],
        Customers: [],
        vat_number: "",
        fax_number: "",
        gdpr_html: "",
        imprint_html: ""

    } as ICompany
}




export const Customers:React.FC = () => {
    const isAdmin = getIsAdmin();

    const [userArray,setUserArray,statusCodeUserArray] = useFetch<IUser[]>("/user/")
    //
    const [contractArray,setContractArray,statusCodeContractArray] = useFetch<IContract[]>("/company/contract/")
    //
    const [genderArray,setGenderArray,statusCodeGenderArray] = useFetch<IGender[]>("/person/gender/");
    const [academicTitleArray,setAcademicTitleArray,statusCodeAcademicTitleArray] = useFetch<IAcademicTitle[]>("/person/academic_title/");
    const [personArray,setPersonArray,statusCodePersonArray] = useFetch<ICustomer[]>("/person/");
    //
    const [serverArray, setServerArray, statusCodeServer] = useFetch<IServer[]>("/server/");
    const [serverTypeArray, setServerTypeArray, statusCodeServerType] = useFetch<IServerType[]>("/server/type/");
    //
    const [appArray,setAppArray,statusCodeAppArray] = useFetch<IApp[]>("/extension/app");
    const [moduleArray,setModuleArray,statusCodeModuleArray] = useFetch<IModule[]>("/extension/module");
    const [schedulingArray,setSchedulingArray,statuCodeSchedulingArray] = useFetch<IScheduling[]>("/subscription/scheduling");
    const [subscriptionArrray,setSubscriptionArray,statuCodesubscription] = useFetch<ISubscription[]>("/subscription/");
    //
    const [currentArray, setCurrentArray]  = useState<ICompany[]>([]);
    const [editSubscription,setEditSubscription] = useState(getEmptyCompany());

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'customer_number', 
            headerName: 'Kundennummer', 
            width: 150 
        },
        { 
            field: 'phone_number', 
            headerName: 'Telefon', 
            width: 150 
        },
        { 
            field: 'email', 
            headerName: 'E-Mail', 
            width: 150 
        },
        { 
            field: 'company_name', 
            headerName: 'Firma', 
            flex: 1
        },
    
    ]

    return(
        <GenericOverview
            title={(isAdmin) ? "Kunden" : "Meine Unternehmen"}
            btnTitle="Neuer Kunde"
            disableBtn={!isAdmin}
            columns={columns}
            editObject={editSubscription}
            setEditObject={setEditSubscription}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/company/"
            getEmptyObject={getEmptyCompany}
            enableDialog
            dialogWidth="md"

        >
            <CompanyEdit
                editCompany={editSubscription}
                setEditCompany={setEditSubscription}
                userArray={userArray || []}
                setUserArray={setUserArray}
                customerArray={personArray || []}
                setCustomerArray={setPersonArray}
                contractArray={contractArray || []}
                setContractArray={setContractArray}
                academicTitleArray={academicTitleArray || []}
                genderArray={genderArray || []}
                appArray={appArray || []}
                moduleArray={moduleArray || []}
                schedulingArray={schedulingArray || []}
                subscriptionArray={subscriptionArrray || []}

                serverArray={serverArray || []}
                setServerArray={setServerArray}
                serverTypeArray={serverTypeArray || []}
            />
        </GenericOverview>
    )
}