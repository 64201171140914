import React, { useState } from "react";
import { Alert, Box, Button, Collapse, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { SelectTable } from "./SelectTable";
import { GridColDef } from "@mui/x-data-grid";
import { uploadFetch } from "../../hooks/useFetch";

export interface IObjectTableCol {
    title: string;
    attr: string;
    castToNumberEur?: boolean;
    castDate?: boolean;
    width?: number;
}


interface IProps {
    columns: GridColDef[];
    objectTableCol: IObjectTableCol[];
    title: string;
    objectArray: any[];
    selectedId: number[];
    setSelectedId: Function;
    //
    setObjectArray?: Function;
    children?: React.ReactElement;
    getNewObject?: Function;
    editObject?: any;
    setEditObject?: Function;
    uploadUrl?: string;
    //
    disableBtns?: boolean;
    disableBtnAssign?: boolean;
}


export const ObjectTable: React.FC<IProps> = ({
    columns, objectTableCol, title, objectArray, selectedId, setSelectedId, children, 
    setObjectArray, getNewObject, editObject, setEditObject, uploadUrl,
    disableBtns, disableBtnAssign
}) => {
    const [isOpenNew,setIsOpenNew] = useState(false);
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);



    const handleRemoveMe = (idApp:number) => {
        setSelectedId([
            ...selectedId.filter(x => x !== idApp)
        ])
    }

    const handleAddNew = () => {
        if (getNewObject !== undefined && setEditObject !== undefined) {
            setWasSuccessfully(true)
            setEditObject(getNewObject());
            setIsOpenNew(true);
        }
    }

    const handleEdit = (id:number) => {
        let testObject = objectArray.find(x => x.id === id);
        if (testObject !== undefined && setEditObject !== undefined) {
            setEditObject(testObject);
            setIsOpenNew(true);
        }
    }

    const handleAfterSave = (savedObject:any) => {
        if (setObjectArray !== undefined) {
            //setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
            let testObject = objectArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setObjectArray([
                    ...objectArray,
                    savedObject
                ])
                setSelectedId([
                    ...selectedId,
                    savedObject.id
                ])
            }
            else {
                setObjectArray([
                    ...objectArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpenNew(false);
        }
    }


    const handleSave = () => {
        if (uploadUrl !== undefined) {
            setWasSuccessfully(true);
            if (editObject.id < 0) {
                uploadFetch(uploadUrl,true,editObject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
            } else {
                uploadFetch(`${uploadUrl}${editObject.id}`,false,editObject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
            }
        } 

    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }




    return (
        <>
            <Dialog open={isOpenNew} onClose={() => setIsOpenNew(false)} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Collapse in={!wasSuccessfully}>
                        <Alert severity="error">
                            Ein Fehler ist aufgetretten!
                        </Alert>
                    </Collapse>
                    {(children !== undefined) && children}
                    
                    <Box sx={{mt: 2}}>
                        <Button sx={{float: "right", ml: 2}}  onClick={handleSubmit} variant="contained" disabled={isLoadgin}>Speichern</Button>
                        <Button sx={{float: "right"}} onClick={() => setIsOpenNew(false)} variant="outlined">Abbruch</Button>
                    </Box>
                </DialogContent>
            </Dialog>


            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                {title}
                {(disableBtns === undefined || disableBtns === false) &&
                    <>
                        <SelectTable 
                            title={title}
                            columns={columns} 
                            currentArray={objectArray} 
                            disabledBtn={
                                (disableBtns === undefined && disableBtnAssign === undefined) 
                                ? false 
                                : (disableBtnAssign !== undefined) ? disableBtnAssign
                                : (disableBtns === undefined) ? false: disableBtns
                            }
                            selectedId={selectedId}
                            setSelectedId={setSelectedId}
                        />
                        {(children !== undefined)
                            && <Button variant="outlined" onClick={handleAddNew} sx={{float: "right", mr: 2}}>Neu</Button>
                        }
                    </>
                }

            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        {objectTableCol.map(x =>
                            <TableCell sx={{width: (x.width === undefined) ? undefined : x.width}}>
                                {x.title}
                            </TableCell>
                        )}
                        <TableCell sx={{ width: 100 }}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedId.map(idApp => {
                        let testApp = objectArray.find(x => x.id === idApp);

                        if (testApp === undefined) {
                            return <TableRow><TableCell colSpan={objectTableCol.length+1}><b>FEHLER</b></TableCell></TableRow>
                        }
                        else {
                            return (
                                <TableRow key={`row-app-${idApp}`}>
                                    {objectTableCol.map(x =>
                                        <TableCell>
                                            {
                                                (x.castToNumberEur === undefined && x.castDate === undefined) 
                                                ? testApp[x.attr]
                                                    : (x.castToNumberEur === true)
                                                    ? Number(testApp[x.attr]).toLocaleString("de-DE", { style: "currency", currency: "EUR" })
                                                    : (x.castDate === true && testApp[x.attr] != undefined)
                                                    ? new Date(testApp[x.attr]).toLocaleDateString()
                                                    : "-"

                                            }
                                        </TableCell>    
                                    )}
                                    <TableCell>
                                        {(disableBtns === undefined || disableBtns === false) &&
                                            <>
                                                {(children !== undefined)
                                                    && <IconButton size="small" onClick={() => handleEdit(idApp)}><Edit/></IconButton>
                                                }
                                                {(disableBtnAssign === undefined || disableBtnAssign === false) &&
                                                    <IconButton size="small" onClick={() => handleRemoveMe(idApp)}><Delete /></IconButton>
                                                }
                                                
                                            </>

                                        }

                                    </TableCell>
                                </TableRow>
                            )
                        }
                    })}
                </TableBody>
            </Table>
        </>
    )
}