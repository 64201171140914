import React, { useState } from "react";
import { ISubscription } from "../../../interfaces/ISubscription";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { IModule } from "../../../interfaces/IModule";
import { columnsModule } from "../../../sites/extension/ModuleOverview";


interface IProps {
    disabledBtn: boolean;
    moduleArray: IModule[];
    assignedModule: number[];
    setAssignedModule: Function;
}


export const ModuleSelect:React.FC<IProps> = ({disabledBtn, moduleArray, assignedModule,setAssignedModule }) => {
    const [isOpenAdd,setIsOpenAdd] = useState(false);
    //
    const [addNewPartner,setAddNewPartner] = useState<GridRowId[]>([]);


    const handleAddNew = () => {
        setAddNewPartner([]);
        setIsOpenAdd(true)
    }

    const handleAddBtn = () => {

        setAssignedModule([...assignedModule, ...addNewPartner]);
        setIsOpenAdd(false);

    }


    return(
        <>
            <Dialog open={isOpenAdd} onClose={() => setIsOpenAdd(false)} maxWidth="md" fullWidth>
                <DialogTitle>App hinzufügen</DialogTitle>
                <DialogContent>
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        rows={moduleArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setAddNewPartner([
                                ...newRowSelectionModel
                            ])                       
                        }}
                        rowSelectionModel={addNewPartner}
                        columns={columnsModule}
                        //getRowId={(row) => row.idContact}
                        //isRowSelectable={(params: GridRowParams) => props.contactArray.map(x => x.idContact).indexOf(Number(params.row.idContact)) === -1 }
                        isRowSelectable={(params: GridRowParams) => assignedModule.indexOf(Number(params.id)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAdd(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddBtn}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
            <Button disabled={disabledBtn} variant="outlined" sx={{float: "right"}} onClick={handleAddNew}>Modul zuordnen</Button>
        </>
    )

}