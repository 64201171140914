import React, { useState } from "react";
import { IServer } from "../interfaces/IServer";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GenericOverview } from "../components/core/GenericOverview";
import { useFetch } from "../hooks/useFetch";
import { IServerType } from "../interfaces/IServerType";
import { IContract } from "../interfaces/IContract";
import { IClient } from "../interfaces/IClient";
import { ClientEdit } from "../components/Client/ClientEdit";
import { IClientType } from "../interfaces/IClientType";
import { getIsAdmin } from "../components/core/helper";
import { ICompany } from "../interfaces/ICompany";
import { Check, Close } from "@mui/icons-material";
import { Box } from "@mui/material";



const getEmptyServer = () => {
    return {
        id: -1,
        ClientType: 1,
        Contract: -1,
        ip: "",
        is_registered: false,
        license_key: "",
        servername: "",
        servername_fqdn: "",
        valide_refresh_token: null,
        valide_token: null
    } as IClient
} 


export const Clients:React.FC = () => {
    const isAdmin = getIsAdmin();
    //
    const [currentArray, setCurrentArray] = useState<IClient[]>([]);
    const [editServer,setEditServer] = useState(getEmptyServer());
    //
    //const [contractArray, setContractArray, statusCodeServerType] = useFetch<IContract[]>("/company/contract");
    //
    const [serverTypeArray, setServerTypeArray, statusCodeServerType] = useFetch<IClientType[]>("/client/type");
    //
    const [companyArray, setCompanyArray, statusCodeCompany] = useFetch<ICompany[]>("/company/");
    const [contractArray, setContractArray, statusCodeContract] = useFetch<IContract[]>("/company/contract/");

    const tsxCurrentStateDot = (currentClient: IClient) : React.ReactNode => {
        //                 Dunkelgrün  Hellgrün     Hellrot     Rot         Gelb        Grau
        let currentColor : "#228B22" | "#99c58f" | "#fc9292" | "#D22B2B" | "#e6e600" | "#c9c9c5" = "#c9c9c5";

        if (currentClient.valide_token !== null && currentClient.valide_refresh_token !== null) {
            let valide_token_date = new Date(currentClient.valide_token);
            let valide_refresh_token_date = new Date(currentClient.valide_refresh_token);
            let current_date = new Date();

            if (currentClient.is_locked) {
                currentColor = "#fc9292";
            }
            else if (valide_token_date > current_date) {
                currentColor = "#99c58f";
            }
            else if (valide_refresh_token_date > current_date) {
                currentColor = "#e6e600";
            }
            else {
                currentColor = "#D22B2B";
            }
        }

        return (
            <Box sx={{mt: 1}}>
                <span style={{
                        height: 25,
                        width: 25,
                        backgroundColor: currentColor,
                        borderRadius: "50%",
                        display: "inline-block",
                    }}
                >
                </span>
            </Box>
        )
    }

    const getValueState = (currentClient: IClient) : React.ReactNode => {
        let value = 0;

        if (currentClient.valide_token !== null && currentClient.valide_refresh_token !== null) {
            let valide_token_date = new Date(currentClient.valide_token);
            let valide_refresh_token_date = new Date(currentClient.valide_refresh_token);
            let current_date = new Date();

            if (currentClient.is_locked) {
                value = 4;
            }
            else if (valide_token_date > current_date) {
                value = 1;
            }
            else if (valide_refresh_token_date > current_date) {
                value = 2;
            }
            else {
                value = 3;
            }
        }

        return value;
    }



    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },  

        { 
            field: 'state', 
            headerName: 'Status', 
            width: 90,
            valueGetter: (value,row) => getValueState(row),
            renderCell: (params: GridRenderCellParams<IClient, undefined>) => tsxCurrentStateDot(params.row)
        },  

        { 
            field: 'ClientType', 
            headerName: 'Art', 
            width: 120,
            renderCell: (params: GridRenderCellParams<any, number>) => serverTypeArray?.find(x => x.id === params.value)?.client_type
        },  

        { 
            field: 'is_registered', 
            headerName: 'Aktiv', 
            width: 90,
            renderCell: (params: GridRenderCellParams<any, boolean>) => (params.value) ? <Check/> : <Close/>
        },  
   
   


        { 
            field: 'Contract', 
            headerName: 'Kunde', 
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, number>) => {
                let company = companyArray?.find(company => company.id === contractArray?.find(contract => contract.id === params.value)?.Company);

                if (company === undefined) {
                    return <></>
                }
                else {
                    return company.company_name
                }

            }
        },  
        { 
            field: 'ip', 
            headerName: 'IP', 
            width: 200
        }, 

        { 
            field: 'servername_fqdn', 
            headerName: 'FQDN', 
            flex: 1
        },  

        { 
            field: 'valide_token', 
            headerName: 'Token Gültig', 
            width: 250,
            renderCell: (params: GridRenderCellParams<any, string | null>) => (params.value === null) ? <></> : new Date(params.value!).toLocaleString()
        },    

        { 
            field: 'valide_refresh_token', 
            headerName: 'Token Refresh Gültig', 
            width: 250,
            renderCell: (params: GridRenderCellParams<any, string | null>) => (params.value === null) ? <></> : new Date(params.value!).toLocaleString()
        }, 
    ]

    return(
        <GenericOverview
            title="Client"
            btnTitle="Neuer Client"
            columns={columns}
            editObject={editServer}
            setEditObject={setEditServer}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/client/"
            getEmptyObject={getEmptyServer}
            enableDialog
            dialogWidth="md"
            disableBtn={!isAdmin}

        >
            <ClientEdit
                editClient={editServer}
                setEditClient={setEditServer}
                clientTypeArray={serverTypeArray || []}
                contractArray={contractArray || []}
            />
        </GenericOverview>
    )
}