import React from "react";
import { Grid } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IModule } from "../../interfaces/IModule";


interface IProps {
    editModule: IModule;
    setEditModule: Function;
}



export const ModuleEdit:React.FC<IProps> = ({editModule,setEditModule}) => {
    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={8}>
                    <CustomeTextField
                        label="Bezeichnung"
                        attr="module_name"
                        type="string"
                        object={editModule}
                        setObject={setEditModule}
                        required
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="System-Schlüssel"
                        attr="system_key"
                        type="string"
                        object={editModule}
                        setObject={setEditModule}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Preis"
                        attr="selling_price"
                        type="number"
                        object={editModule}
                        setObject={setEditModule}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Hervorheben im Marketplace"
                        attr="isHighlighted"
                        type="boolean"
                        object={editModule}
                        setObject={setEditModule}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Beschreibung"
                        attr="description"
                        type="string"
                        object={editModule}
                        setObject={setEditModule}
                        rows={5}
                    />
                </Grid>
            </Grid>

        </>
    )
}