import React, { useState } from "react";
import { IWebsite } from "../../interfaces/IWebsite";
import { ICompany } from "../../interfaces/ICompany";
import { IContract } from "../../interfaces/IContract";
import { Collapse, Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { getIsAdmin } from "../core/helper";
import { WebsiteVersionTable } from "./Childs/WebsiteVersionTable";
import { ICustomer } from "../../interfaces/ICustomer";
import { IServer } from "../../interfaces/IServer";
import { IMIMEtype } from "../../interfaces/IMIMEtype";


interface IProps {
    editWebsite: IWebsite;
    setEditWebsite: Function;
    websiteArray: IWebsite[];
    companyArray: ICompany[];
    contractArray: IContract[];
    personArray: ICustomer[];
    serverArray: IServer[];
    mimeTypeArray: IMIMEtype[];
}


export const WebsiteEdit:React.FC<IProps> = ({editWebsite,setEditWebsite, websiteArray, companyArray, contractArray, personArray, serverArray, mimeTypeArray}) => {
    const isAdmin = getIsAdmin();
    //
    const [orgWebsite,setOrgWebsite] = useState(editWebsite);
    const [websiteVersionArray,setWebsiteVersionArray] = useState(editWebsite.WebVersions);


    const filterPerson = () => {
        if (editWebsite.Contract === null) {
            return [] as ICustomer[];
        }
        else {
            let company = companyArray.find(x => x.id === contractArray.find(y => y.id === editWebsite.Contract)?.Company)
            
            if (company === undefined) {
                return [] as ICustomer[];
            }
            else {
                return [...personArray.filter(x => company!.Customers.indexOf(x.id) > -1)]
            }
        }
    }


    return(
        <>
            <Collapse in={orgWebsite.id > 0}>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <b>Lizenz</b>
                    </Grid>
                    <Grid item sm={8}>
                        {orgWebsite.license_key}
                    </Grid>
                </Grid>
            </Collapse>

            <Collapse in={orgWebsite.id < 0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Nutze Vorlage"
                            attr="id_template"
                            type="select"
                            object={editWebsite}
                            setObject={setEditWebsite}
                            null
                        >
                            {websiteArray.filter(x => x.isTemplate === true).map(x =>
                                <MenuItem key={`select-websitetemplate-${x.id}`} value={x.id}>
                                    {x.website_title}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Ist Vorlage"
                            attr="isTemplate"
                            type="boolean"
                            object={editWebsite}
                            setObject={setEditWebsite}
                        />
                    </Grid>
                </Grid>
            </Collapse>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                    {(isAdmin) &&
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Server"
                                attr="ServerInstance"
                                type="select"
                                object={editWebsite}
                                setObject={setEditWebsite}
                                disabled={editWebsite.id > 0}
                                null
                            >
                                {serverArray.map(x =>
                                    <MenuItem key={`select-server-${x.id}`} value={x.id}>
                                        {x.servername}
                                    </MenuItem>
                                )}
                            </CustomeTextField>
                        </Grid>
                    }
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Abonnement"
                        attr="Contract"
                        type="select"
                        object={editWebsite}
                        setObject={setEditWebsite}
                        disabled={orgWebsite.id > 0}
                        null
                    >
                        {contractArray.map(x =>
                            <MenuItem key={`select-contract-${x.id}`} value={x.id}>
                                {companyArray.find(y => y.id === x.Company)?.company_name}: {x.contract_number} / {x.contract_title}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Titel der Webseite"
                        attr="website_title"
                        type="string"
                        object={editWebsite}
                        setObject={setEditWebsite}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="URL"
                        attr="url"
                        type="string"
                        object={editWebsite}
                        setObject={setEditWebsite}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Ziel-E-Mail des Kontaktfeldes"
                        attr="email_contactfield"
                        type="email"
                        object={editWebsite}
                        setObject={setEditWebsite}
                        required
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Karten-URL"
                        attr="card_link"
                        type="string"
                        object={editWebsite}
                        setObject={setEditWebsite}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Google-Maps-URL"
                        attr="google_card_link"
                        type="string"
                        object={editWebsite}
                        setObject={setEditWebsite}
                    />
                </Grid>
            </Grid>


            {(orgWebsite.id > 0) &&
                <WebsiteVersionTable
                    website={orgWebsite}
                    websiteVersionArray={websiteVersionArray}
                    setWebsiteVersionArray={setWebsiteVersionArray}
                    personArray={filterPerson()}
                    mimeTypeArray={mimeTypeArray}
                />
            }
        </>
    )
}