import React, { useState } from "react";
import { ISubscription } from "../../../interfaces/ISubscription";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { IApp } from "../../../interfaces/IApp";
import { columnsApp } from "../../../sites/extension/AppOverview";


interface IProps {
    disabledBtn: boolean;
    appArray: IApp[];
    assignedApps: number[];
    setAssignedApps: Function;
}


export const AppSelect:React.FC<IProps> = ({disabledBtn, appArray, assignedApps,setAssignedApps }) => {
    const [isOpenAdd,setIsOpenAdd] = useState(false);
    //
    const [addNewPartner,setAddNewPartner] = useState<GridRowId[]>([]);


    const handleAddNew = () => {
        setAddNewPartner([]);
        setIsOpenAdd(true)
    }

    const handleAddBtn = () => {

        setAssignedApps([...assignedApps, ...addNewPartner]);
        setIsOpenAdd(false);

    }


    return(
        <>
            <Dialog open={isOpenAdd} onClose={() => setIsOpenAdd(false)} maxWidth="md" fullWidth>
                <DialogTitle>App hinzufügen</DialogTitle>
                <DialogContent>
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        rows={appArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setAddNewPartner([
                                ...newRowSelectionModel
                            ])                       
                        }}
                        rowSelectionModel={addNewPartner}
                        columns={columnsApp}
                        //getRowId={(row) => row.idContact}
                        //isRowSelectable={(params: GridRowParams) => props.contactArray.map(x => x.idContact).indexOf(Number(params.row.idContact)) === -1 }
                        isRowSelectable={(params: GridRowParams) => assignedApps.indexOf(Number(params.id)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAdd(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddBtn}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
            <Button disabled={disabledBtn} variant="outlined" sx={{float: "right"}} onClick={handleAddNew}>App zuordnen</Button>
        </>
    )

}