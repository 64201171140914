import React, { useEffect, useState } from "react";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { IApp } from "../../interfaces/IApp";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Alert, Button, Collapse, Grid, LinearProgress, Typography } from "@mui/material";
import { AppEdit } from "../../components/App/AppEdit";
import { IModule } from "../../interfaces/IModule";
import { GenericOverview } from "../../components/core/GenericOverview";
import { ModuleEdit } from "../../components/Module/ModuleEdit";


export const columnsModule: GridColDef[] = [
    { 
        field: 'id', 
        headerName: 'Nr.', 
        width: 90 
    },
    { 
        field: 'system_key', 
        headerName: 'System-Schlüssel', 
        width: 200 
    },
    { 
        field: 'selling_price', 
        headerName: 'Preis (netto)', 
        valueGetter: (value) => Number(value).toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
        width: 100 
    },
    { 
        field: 'module_name', 
        headerName: 'Bezeichnung', 
        flex: 1
    },

]


const getEmptyApp = () => {
    return {
        module_name: "",
        id: -1,
        isHighlighted: false,
        selling_price: 0,
        system_key: "",
        description: ""
    } as IModule
}


export const ModuleOverview:React.FC = () => {
    const [moduleArray, setModuleArray]  = useState<IModule[]>([]);
    const [editModule,setEditModule] = useState(getEmptyApp());


    return(
        <GenericOverview
            title="Module"
            btnTitle="Neues Modul"
            columns={columnsModule}
            editObject={editModule}
            setEditObject={setEditModule}
            currentArray={moduleArray}
            setCurrentArray={setModuleArray}
            fetchUrl="/extension/module/"
            getEmptyObject={getEmptyApp}
        >
            <ModuleEdit
                key={`module-${editModule.id}`}
                editModule={editModule}
                setEditModule={setEditModule}
            />
        </GenericOverview>
    )

}