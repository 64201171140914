import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Card, CardContent, Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getIsAdmin } from "../../core/helper";
import { ArrowUpward, Delete, Upload, UploadFile } from "@mui/icons-material";
import { CustomEditor } from "../../core/CustomEditor";
import { IWebsiteSubsite } from "../../../interfaces/IWebsiteSubsite";
import { IWebsitePerson } from "../../../interfaces/IWebsitePerson";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IWebsitePicture } from "../../../interfaces/IWebsitePicture";
import { IMIMEtype } from "../../../interfaces/IMIMEtype";

interface IProps {
    picture: IWebsitePicture;
    pictureArray: IWebsitePicture[];
    setPictureArray: Function;
    mimeTypeArray : IMIMEtype[];
}


export const WebsitePictureEntry:React.FC<IProps> = ({picture, pictureArray, setPictureArray, mimeTypeArray}) => {
    const isAdmin = getIsAdmin();
    const inputUpload = useRef<HTMLInputElement | null>(null);
    //
    
    const [editObject,setEditObject] = useState(picture);



    useEffect(() => {
        setPictureArray([
            ...pictureArray.map(x => x.id === editObject.id ? editObject : x)
        ])
    },[editObject])


    async function fileToBase64(file:File) : Promise<[string, string, number]> {
        let mime_type = file.type;
        let test = mimeTypeArray.find(x => x.MIMEtype === mime_type);

        if (test === undefined) {
            return ["","",1]
        }

        let result_base64 = await new Promise((resolve) => {            
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
            console.log(file);
        });

        return [String(result_base64).split(",")[1],file.name, test.id];
    }

    const handleFilesUpload = async (acceptedFiles:File[]) => {
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let updateProfile = {...editObject};
            [updateProfile.picture, updateProfile.file_name, updateProfile.MIMEtype] = await fileToBase64(currentFile);
            setEditObject({...updateProfile})
        }
    }
    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }


    const handleRemoveMe = () => {
        setPictureArray([
            ...pictureArray
                .filter(x => x.id !== picture.id)
        ])
    }


    return(
        <Box sx={{mt: 5}}>  
            <Card variant="outlined">
                <CardContent>
                <input 
                    hidden 
                    ref={inputUpload} 
                    type="file" 
                    accept={mimeTypeArray.map(x => x.MIMEtype).join(", ") }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
                />

                <Grid container spacing={2}>
                    <Grid item sm={11}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                {(editObject.picture !== "")
                                    &&                        
                                        <Box sx={{mt: 5}}   
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                                <img width="50%" src={`data:${mimeTypeArray.find(x => x.id === editObject.MIMEtype)?.MIMEtype};base64, ${editObject.picture}`} title={editObject.file_name}/>
                                        </Box>
                                    
                                }
                            </Grid>
                            <Grid item sm={10}>
                                <CustomeTextField
                                    label="Datenname"
                                    attr="file_name"
                                    type="string"
                                    object={editObject}
                                    setObject={setEditObject}
                                    disabled={!isAdmin && editObject.id > 0}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Button sx={{float: "left"}} onClick={() => (inputUpload !== null) && inputUpload.current?.click()} ><UploadFile/>Hochladen</Button>
                            </Grid>
                            <Grid item sm={12} sx={{display: "flex"}}>
                                <Box sx={{mr: 5}}><b>Art:</b></Box>
                                {(editObject.picture !== "")
                                    &&                        
                                    <>{mimeTypeArray.find(x => x.id === editObject.MIMEtype)?.MIMEtype}</>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={1}>
                        <IconButton disabled={!isAdmin} onClick={handleRemoveMe}><Delete/></IconButton>
                    </Grid>
                </Grid>

                </CardContent>
            </Card>
        </Box>
    )
}