import React from "react";
import { ISubscription } from "../../../interfaces/ISubscription";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { AppSelect } from "./AppSelect";
import { Delete } from "@mui/icons-material";
import { IModule } from "../../../interfaces/IModule";
import { ModuleSelect } from "./ModuleSelect";
import { IContract } from "../../../interfaces/IContract";


interface IProps {
    editSubscription: ISubscription | IContract;
    moduleArray: IModule[];
    assignedModule: number[];
    setAssignedModule: Function;
    disableBtn?: boolean;
}


export const ModuleTable: React.FC<IProps> = ({ editSubscription, moduleArray, assignedModule, setAssignedModule, disableBtn }) => {

    const handleRemoveMe = (idApp:number) => {
        setAssignedModule([
            ...assignedModule.filter(x => x !== idApp)
        ])
    }

    const tableBody = () => {
        if (editSubscription.isFreeAll == true) {
            return (
                <>
                    {moduleArray.map(x =>
                        <TableRow>
                            <TableCell>{Number(x.selling_price).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                            <TableCell>{x.module_name}</TableCell>
                            <TableCell>-</TableCell>
                        </TableRow>
                    )}
                    <TableRow><TableCell colSpan={3}><i>Automatisch alle neuen Module...</i></TableCell></TableRow>
                </>
            )
        } else {
            return (
                <>
                    {assignedModule.map(idApp => {
                        let testApp = moduleArray.find(x => x.id === idApp);

                        if (testApp === undefined) {
                            return <TableRow><TableCell colSpan={3}><b>FEHLER</b></TableCell></TableRow>
                        }
                        else {
                            return (
                                <TableRow key={`row-app-${idApp}`}>
                                    <TableCell>{Number(testApp.selling_price).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                                    <TableCell>{testApp.module_name}</TableCell>
                                    <TableCell>
                                        {(disableBtn === undefined || disableBtn === false) && 
                                            <IconButton size="small" onClick={() => handleRemoveMe(idApp)}><Delete /></IconButton>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    })}
                </>
            )
        }
    }


    return (
        <>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Zugeordnete Module
                {(disableBtn === undefined || disableBtn === false) && 
                    <ModuleSelect disabledBtn={editSubscription.isFreeAll} moduleArray={moduleArray} assignedModule={assignedModule} setAssignedModule={setAssignedModule} />
                }
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 70 }}>Preis</TableCell>
                        <TableCell>Modul</TableCell>
                        <TableCell sx={{ width: 50 }}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody()}
                </TableBody>
            </Table>
        </>
    )
}