import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, IconButton, MenuItem } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getIsAdmin } from "../../core/helper";
import { ArrowUpward, Delete } from "@mui/icons-material";
import { CustomEditor } from "../../core/CustomEditor";
import { IWebsiteSubsite } from "../../../interfaces/IWebsiteSubsite";
import { IWebsitePerson } from "../../../interfaces/IWebsitePerson";
import { ICustomer } from "../../../interfaces/ICustomer";

interface IProps {
    webPerson: IWebsitePerson;
    webPersonArray: IWebsitePerson[];
    setWebPersonArray: Function;
    personArray : ICustomer[];
}


export const WebsitePersonEntry:React.FC<IProps> = ({webPerson, webPersonArray, setWebPersonArray, personArray}) => {
    const isAdmin = getIsAdmin();

    const [editObject,setEditObject] = useState(webPerson);

    const setText = (txt: string) => {
        setEditObject({... editObject, long_description : txt});
    }



    useEffect(() => {
        setWebPersonArray([
            ...webPersonArray.map(x => x.id === editObject.id ? editObject : x)
        ])
    },[editObject])


    const handleRemoveMe = () => {
        setWebPersonArray([
            ...webPersonArray
                .filter(x => x.id !== webPerson.id)
                .map(x => x.position > webPerson.position ? {...x, position: x.position -1} : x )
        ])
    }


    return(
        <Box sx={{mt: 5}}>  
            <Card variant="outlined">
                <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={11}>
                        <Grid container spacing={2}>

                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Zugeordnete Person"
                                    attr="Person"
                                    type="select"
                                    object={editObject}
                                    setObject={setEditObject}
                                    disabled={!isAdmin && editObject.id > 0}
                                    null
                                >
                                    {personArray.map(x =>
                                        <MenuItem key={`select-person-${x.id}`} value={x.id}>{x.first_name} {x.last_name}</MenuItem>
                                    )}
                                </CustomeTextField>
                            </Grid>

                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Kurzbeschreibung"
                                    attr="small_description"
                                    type="string"
                                    object={editObject}
                                    setObject={setEditObject}
                                    disabled={!isAdmin && editObject.id > 0}
                                    rows={5}
                                />
                            </Grid>

                            <CustomEditor txt={editObject.long_description} setTxt={setText}/>
                        </Grid>
                    </Grid>
                    <Grid item sm={1}>
                        <IconButton disabled={!isAdmin} onClick={handleRemoveMe}><Delete/></IconButton>
                    </Grid>
                </Grid>

                </CardContent>
            </Card>
        </Box>
    )
}