import React, { useEffect, useState } from "react";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { IApp } from "../../interfaces/IApp";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Alert, Button, Collapse, Grid, LinearProgress, Typography } from "@mui/material";
import { AppEdit } from "../../components/App/AppEdit";




const getEmptyApp = () => {
    return {
        app_name: "",
        id: -1,
        isHighlighted: false,
        selling_price: 0,
        system_key: "",
        description: ""
    } as IApp
}

export const columnsApp: GridColDef[] = [
    { 
        field: 'id', 
        headerName: 'Nr.', 
        width: 90 
    },
    { 
        field: 'system_key', 
        headerName: 'System-Schlüssel', 
        width: 200 
    },
    { 
        field: 'selling_price', 
        headerName: 'Preis (netto)', 
        valueGetter: (value) => Number(value).toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
        width: 100 
    },
    { 
        field: 'app_name', 
        headerName: 'Bezeichnung', 
        flex: 1
    },

]


export const AppOverview:React.FC = () => {
    const [appArray,setAppArray,statusCodeAppArray] = useFetch<IApp[]>("/extension/app");
    //
    const [editApp,setEditApp] = useState(getEmptyApp());
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    

    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = appArray?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditApp(getEmptyApp())
        } else {
            setEditApp(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditApp(getEmptyApp());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject:IApp) => {
        if (appArray !== undefined) {
            //setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
        
            let testObject = appArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setAppArray([
                    ...appArray,
                    savedObject
                ])
            }
            else {
                setAppArray([
                    ...appArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    
    const handleSave = () => {
        setWasSuccessfully(true);
        if (editApp.id < 0) {
            uploadFetch("/extension/app/",true,editApp,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            uploadFetch(`/extension/app/${editApp.id}`,false,editApp,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            //setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
            setIsLoading(false);
        }
    },[wasSuccessfully])


    if (
        (statusCodeAppArray !== null && statusCodeAppArray !== 200)
    ) { return <Alert severity="error">Fehler!</Alert> }

    else if (
        appArray === undefined
    ) { return <LinearProgress/> }

    else {
        return(
            <>
                <Typography variant="h5">
                    Apps
                    <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Neue App</Button>
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                {/* <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/> */}


                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={(isOpen) ? 6 : 12}>
                        <DataGrid
                            rows={appArray}
                            columns={columnsApp}
                            //getRowId={(row) => row.id}
                            onRowClick={(params,event,details) => handleChangeId(params)}
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={(isOpen) ? 6 : 0}>
                        <Collapse in={isOpen}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Leistung
                                    <Button sx={{float: "right", ml: 2}} type="submit" variant="contained" disabled={isLoadgin}>Speichern</Button>
                                    <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                                </Typography>
                                <AppEdit
                                    key={`app-${editApp.id}`}
                                    editApp={editApp}
                                    setEditApp={setEditApp}
                                />
                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }


}