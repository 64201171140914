import React, { useState } from "react";
import { IUser } from "../../interfaces/IUser";
import { Collapse, Grid, TextField, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { UserSetPassword } from "./UserSetPassword";


interface IProps {
    userEdit: IUser;
    setUserEdit: Function;
}


export const UserEdit:React.FC<IProps> = ({userEdit, setUserEdit}) => {
    const [isAdmin,setIsAdmin] = useState(localStorage.getItem("is_superuser") === "true")
    //
    const [isSetPwEnabled,setIsSetPwEnabled] = useState(false);
    const [isPwValid, setIsPwValid] = useState(false);


    const onBlur = (pw:string) => {
        if (pw === "") {
            setIsSetPwEnabled(false);
        }
    }
    

    return(
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {(isAdmin) &&
                    <Grid item sm={2}>
                        <CustomeTextField
                            label="Admin"
                            attr="is_superuser"
                            type="boolean"
                            object={userEdit}
                            setObject={setUserEdit}
                            required
                        />
                    </Grid>
                }

                <Grid item sm={(isAdmin) ? 10 : 12}>
                    <CustomeTextField
                        label="Benutername"
                        attr="username"
                        type="string"
                        object={userEdit}
                        setObject={setUserEdit}
                        required
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Vorname"
                        attr="first_name"
                        type="string"
                        object={userEdit}
                        setObject={setUserEdit}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Nachname"
                        attr="last_name"
                        type="string"
                        object={userEdit}
                        setObject={setUserEdit}
                    />
                </Grid>


                <Grid item sm={4}>
                    <Typography sx={{mt: 1}}><b>Passwort:</b></Typography>
                </Grid>
                <Grid item sm={8}>
                    <Collapse in={isSetPwEnabled}>
                        <Grid container>
                            <UserSetPassword
                    userObject={userEdit}
                    setUserObject={setUserEdit}
                                setIsValid={setIsPwValid}
                                onBlur={onBlur}
                            />
                        </Grid>
                    </Collapse>

                    <Collapse in={!isSetPwEnabled}>

                        <TextField
                            label="Password"
                            value={"Secr***"}
                            size="small"
                            fullWidth
                            onFocus={() => setIsSetPwEnabled(true)}
                        />
                    </Collapse>

                </Grid>
            </Grid>
        </>
    )
}