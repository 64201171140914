import { MenuItem, TextField } from "@mui/material";
import React from "react";
//import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  

interface IProps {
    label: string;
    type: "password" | "string" | "email" | "number" | "boolean" | "select" | "date" | "color"
    attr: string;
    object: any;
    setObject: Function;
    required?: boolean;
    variant?: "filled" | "outlined" | "standard";
    rows?: number;
    children?: React.ReactElement[];
    size?: "small" | "medium"
    null?: boolean;
    disabled?: boolean;
}



export const CustomeTextField:React.FC<IProps> = (props) => {

    const getValue = () => {
        if (props.type === "select" && props.object[props.attr] < 0) {
            return "";
        }
        else if (props.null !== undefined && props.null === true && props.object[props.attr] === null) {
            return "";
        }
        else {
            return props.object[props.attr];
        }
    }


    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        let changeObject = {...props.object};

        if (props.type === "boolean") {
            changeObject[props.attr] = event.target.value === "true" ;
        }
        else if (props.type === "password" || props.type === "string" || props.type === "email" || props.type === "date" || props.type === "color") {

            if (props.null !== undefined && props.null === true && event.target.value === "") {
                changeObject[props.attr] = null;    
            } else {
                changeObject[props.attr] = event.target.value;
            }
        }
        else if (props.type === "number" || props.type === "select") {
            if (props.null !== undefined && props.null === true && event.target.value === "") {
                changeObject[props.attr] = null;    
            } else {
                changeObject[props.attr] = Number(event.target.value);
            }
        }

        props.setObject(changeObject);
    }


    if (props.type === "select") {
        return(
            <>
                <TextField
                    //type="email"
                    variant={(props.variant === undefined) ? "outlined" : props.variant}
                    label={props.label}
                    fullWidth
                    name={`${props.type}-${props.attr}`}
                    value={
                        (props.null === undefined || props.null === false) 
                        ? Number(props.object[props.attr])
                        : (props.object[props.attr] === null) ? "" : props.object[props.attr]
                    } 
                    onChange={handleChange} 
                    required={(props.required == undefined) ? false : props.required}
                    select
                    size={(props.size !== undefined) ? props.size : "small"}
                    disabled={props.disabled === undefined ? false: props.disabled}
                >
                    {(props.null !== undefined && props.null === true) &&
                        <MenuItem key={`remove-${props.attr}`} value={""}><i>Auswahl entfernen</i></MenuItem>
                    }
                    {props.children}
                </TextField>

            </>
        )
    }
    else if (props.type === "boolean") {
        return(
            <>
                <TextField
                    //type="email"
                    variant={(props.variant === undefined) ? "outlined" : props.variant}
                    label={props.label}
                    fullWidth
                    name={`${props.type}-${props.attr}`}
                    value={Boolean(props.object[props.attr]) ? "true" : "false"} 
                    onChange={handleChange} 
                    required={(props.required == undefined) ? false : props.required}
                    select
                    size={(props.size !== undefined) ? props.size : "small"}
                    disabled={props.disabled === undefined ? false: props.disabled}
                >
                    <MenuItem value="true">Ja</MenuItem>
                    <MenuItem value="false">Nein</MenuItem>
                </TextField>

            </>
        )
    }
    else {
        return(
            <>
                <TextField
                    type={props.type === "string" ? "text" : props.type}
                    //type="email"
                    variant={(props.variant === undefined) ? "outlined" : props.variant}
                    label={props.label}
                    fullWidth
                    name={`${props.type}-${props.attr}`}
                    value={getValue()} 
                    onChange={handleChange} 
                    required={(props.required == undefined) ? false : props.required}
                    multiline={(props.rows === undefined) ? false : true}
                    rows={props.rows}
                    size={(props.size !== undefined) ? props.size : "small"}
                    InputLabelProps={(props.type === "date" || props.type === "color") ? { shrink: true } : undefined}
                    disabled={props.disabled === undefined ? false: props.disabled}
                />
            </>
        )
    }
}