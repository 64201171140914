import React, { useState } from "react";
import { IWebsite } from "../../../interfaces/IWebsite";
import { IWebVersion } from "../../../interfaces/IWebVersion";
import { AppBar, Box, Dialog, IconButton, Slide, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import { WebsiteVersionEdit } from "./WebsiteVersionEdit";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IMIMEtype } from "../../../interfaces/IMIMEtype";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  







interface IProps {
    website: IWebsite;
    websiteVersionArray: IWebVersion[];
    setWebsiteVersionArray: Function;
    personArray: ICustomer[];
    mimeTypeArray: IMIMEtype[];
}



export const WebsiteVersionTable:React.FC<IProps> = ({website, websiteVersionArray, setWebsiteVersionArray, personArray, mimeTypeArray}) => {
    const [isOpen,setIsOpen] = useState(false);
    const [idWebVersion,setIdWebVersion] = useState(-1);

    
    const handleOpen = (id:number) => {
        setIdWebVersion(id);
        setIsOpen(true);
    }


    return(
        <Box sx={{mt: 2}}>
            <Dialog
                fullScreen
                open={isOpen}
                onClose={() => setIsOpen(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setIsOpen(false)}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Webseiten Editor</Typography>
                    </Toolbar>
                </AppBar>

                <Box sx={{m: 5}}>
                    <WebsiteVersionEdit
                        key={`dialog-webversion-${idWebVersion}`}
                        website={website}
                        idWebsiteVersion={idWebVersion}
                        websiteVersionArray={websiteVersionArray}
                        setWebsiteVersionArray={setWebsiteVersionArray}
                        setIsOpen={setIsOpen}
                        personArray={personArray}
                        mimeTypeArray={mimeTypeArray}
                    />
                </Box>
            </Dialog>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 200}}>Version</TableCell>
                        <TableCell>Veröffentlicht</TableCell>
                    </TableRow>

                </TableHead>
                <TableBody>
                    {websiteVersionArray.sort((a,b) => a.version > b.version ? -1 : 1 ).map(x =>
                        <TableRow sx={{cursor: "pointer"}} onClick={() => handleOpen(x.id)} >
                            <TableCell>{x.version}</TableCell>
                            <TableCell>{(x.published_at === null) ? "-" : new Date(x.published_at).toLocaleDateString()}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}