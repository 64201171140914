import React from "react";
import { ICustomer } from "../../interfaces/ICustomer";
import { IGender } from "../../interfaces/IGender";
import { IAcademicTitle } from "../../interfaces/IAcademicTitle";
import { Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";


interface IProps {
    editPerson: ICustomer;
    setEditPerson: Function;
    genderArray: IGender[];
    academicTitleArray: IAcademicTitle[]
}


export const PersonEdit:React.FC<IProps> = ({editPerson, setEditPerson, genderArray, academicTitleArray}) => {
    return(
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={3}>
                    <CustomeTextField
                        label="Titel"
                        attr="Title"
                        type="select"
                        object={editPerson}
                        setObject={setEditPerson}
                        required
                    >
                        {academicTitleArray.map(x =>
                            <MenuItem key={`title-${x.id}`} value={x.id}>{x.title}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={3}>
                    <CustomeTextField
                        label="Geschlecht"
                        attr="Gender"
                        type="select"
                        object={editPerson}
                        setObject={setEditPerson}
                        required
                    >
                        {genderArray.map(x =>
                            <MenuItem key={`gender-${x.id}`} value={x.id}>{x.gender}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Nachname"
                        attr="last_name"
                        type="string"
                        object={editPerson}
                        setObject={setEditPerson}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Vorname"
                        attr="first_name"
                        type="string"
                        object={editPerson}
                        setObject={setEditPerson}
                        required
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Rufnummer"
                        attr="mobil"
                        type="string"
                        object={editPerson}
                        setObject={setEditPerson}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="E-Mail"
                        attr="email"
                        type="string"
                        object={editPerson}
                        setObject={setEditPerson}
                    />
                </Grid>

            </Grid>
        </>
    )
}