import React from "react";
import { useFetch } from "../../hooks/useFetch";
import { CircularProgress } from "@mui/material";
import { Login } from "./Login";


interface IProps {
    children: React.ReactElement;
}



export const SecurityDriver:React.FC<IProps> = ({children}) => {
    const [result,setResult,statusCode] = useFetch("/user/validate/");


    if (statusCode === null ) { return <CircularProgress/> }
    else if (statusCode === 200) { return children } 
    else { return <Login/> }
}