import React, { useState } from "react";
import { IUser } from "../../../interfaces/IUser";
import { GridColDef } from "@mui/x-data-grid";
import { IObjectTableCol, ObjectTable } from "../../core/ObjectTable";
import { UserEdit } from "../../User/UserEdit";
import { ICustomer } from "../../../interfaces/ICustomer";
import { PersonEdit } from "../PersonEdit";
import { IGender } from "../../../interfaces/IGender";
import { IAcademicTitle } from "../../../interfaces/IAcademicTitle";
import { getIsAdmin } from "../../core/helper";

interface IProps {
    idCompany: number;
    personArray: ICustomer[];
    setPersonArray: Function;
    personIdArray: number[];
    setPersonIdArray: Function;
    genderArray: IGender[];
    academicTitleArray: IAcademicTitle[];
}

const getEmptyPerson = (idCompany:number) => {
    return {
        id: -1,
        Company: idCompany,
        email: "",
        first_name: "",
        Gender: 1,
        last_name: "",
        mobil: "",
        Title: 1
    } as ICustomer
}




export const PersonTable:React.FC<IProps> = ({idCompany, personArray, setPersonArray,  personIdArray, setPersonIdArray, genderArray, academicTitleArray}) => {
    const isAdmin = getIsAdmin();

    const getWrapperEmptyPerson = () => {
        return getEmptyPerson(idCompany)
    }
    
    
    const [editPerson,setEditPerson] = useState(getWrapperEmptyPerson());


    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        {
            headerName: "Rufnummer",
            field: "mobil",
            width: 150
        },
        {
            headerName: "E-Mail",
            field: "email",
            width: 150
        },
        {
            headerName: "Vorname",
            field: "first_name",
            flex: 1
        },
        {
            headerName: "Nachname",
            field: "last_name",
            flex: 1
        }

    ]

    const tableCol:IObjectTableCol[] = [
        {
            title: "Nr.",
            attr: "id"
        },
        {
            title: "Rufnummer",
            attr: "mobil",
            width: 150
        },
        {
            title: "E-Mail",
            attr: "email",
            width: 150
        },
        {
            title: "Vorname",
            attr: "first_name"
        },
        {
            title: "Nachname",
            attr: "last_name"
        }

    ]


    return(
        <>
            <ObjectTable
                columns={columns}
                objectTableCol={tableCol}
                title="Personen"
                objectArray={personArray}
                selectedId={personIdArray}
                setSelectedId={setPersonIdArray}
                //
                setObjectArray={setPersonArray}
                editObject={editPerson}
                setEditObject={setEditPerson}
                getNewObject={getWrapperEmptyPerson}
                uploadUrl="/person/"
                disableBtns={!isAdmin}
            >
                <PersonEdit
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    academicTitleArray={academicTitleArray}
                    genderArray={genderArray}
                />
            </ObjectTable>
        </>
    )
}