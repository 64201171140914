import React, { useEffect, useState } from "react";
import { IWebsiteText } from "../../../interfaces/IWebsiteText";
import { Box, Card, CardContent, FormControlLabel, FormGroup, Grid, IconButton, Switch } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getIsAdmin } from "../../core/helper";
import { ArrowUpward, Delete } from "@mui/icons-material";
import { CustomEditor } from "../../core/CustomEditor";
import "../App.css"


interface IProps {
    websiteText: IWebsiteText;
    websiteTextArray: IWebsiteText[];
    setWebsiteTextArray: Function;
}


export const WebsiteTextEntry:React.FC<IProps> = ({websiteText, websiteTextArray, setWebsiteTextArray}) => {
    const isAdmin = getIsAdmin();

    const [editWebsiteText,setEditWebsiteText] = useState(websiteText);
    //
    const [isEnablePreview,setIsEnablePreview] = useState(true);

    const setText = (txt: string) => {
        setEditWebsiteText({... editWebsiteText, text : txt});
    }

    const handleRemoveMe = () => {
        setWebsiteTextArray([
            ...websiteTextArray
                .filter(x => x.id !== websiteText.id)
                .map(x => x.position > websiteText.position ? {...x, position: x.position -1} : x )
        ])
    }

    useEffect(() => {
        setWebsiteTextArray([
            ...websiteTextArray.map(x => x.id === editWebsiteText.id ? editWebsiteText : x)
        ])
    },[editWebsiteText])



    return(
        <Box sx={{mt: 5}}>  
            <Card variant="outlined">
                <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={11}>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Systemvariable"
                                    attr="system_key"
                                    type="string"
                                    object={editWebsiteText}
                                    setObject={setEditWebsiteText}
                                    disabled={!isAdmin && editWebsiteText.id > 0}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Interner Titel"
                                    attr="intern_title"
                                    type="string"
                                    object={editWebsiteText}
                                    setObject={setEditWebsiteText}
                                    disabled={!isAdmin && editWebsiteText.id > 0}
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Switch
                                            value={isEnablePreview} 
                                            onChange={() => setIsEnablePreview(!isEnablePreview)}
                                        />
                                    } label="Vorschau verstecken" />
                                </FormGroup>
                            </Grid>

                            <Grid item sm={(isEnablePreview) ? 6 : 12}>
                                <CustomeTextField
                                    label="Interner Titel"
                                    attr="text"
                                    type="string"
                                    object={editWebsiteText}
                                    setObject={setEditWebsiteText}
                                    disabled={!isAdmin && editWebsiteText.id > 0}
                                    rows={15}
                                />
                            </Grid>
                            <Grid item sm={(isEnablePreview) ? 6 : 0}>
                                <div dangerouslySetInnerHTML={{__html : editWebsiteText.text}} />
                            </Grid>


                            {/*<CustomEditor txt={editWebsiteText.text} setTxt={setText}/>*/}
                            
                        </Grid>
                    </Grid>
                    <Grid item sm={1}>
                        <IconButton disabled={!isAdmin} onClick={handleRemoveMe}><Delete/></IconButton>
                    </Grid>
                </Grid>

                </CardContent>
            </Card>
        </Box>
    )
}