import React, { useState } from "react";
import { IUser } from "../../../interfaces/IUser";
import { GridColDef } from "@mui/x-data-grid";
import { IObjectTableCol, ObjectTable } from "../../core/ObjectTable";
import { UserEdit } from "../../User/UserEdit";
import { ICustomer } from "../../../interfaces/ICustomer";
import { PersonEdit } from "../PersonEdit";
import { IGender } from "../../../interfaces/IGender";
import { IAcademicTitle } from "../../../interfaces/IAcademicTitle";
import { getIsAdmin } from "../../core/helper";
import { IServerType } from "../../../interfaces/IServerType";
import { IServer } from "../../../interfaces/IServer";
import { ServerEdit } from "../../Server/ServerEdit";
import { IContract } from "../../../interfaces/IContract";

interface IProps {
    idCompany: number;
    serverTypeArray: IServerType[];
    serverArray: IServer[];
    setServerArray: Function;
    serverIdArray: number[];
    setServerIdArray: Function; 

    contractArray: IContract[];
    contractIdArray: number[];
}

const getEmptyServer = () => {
    return {
        id: -1,
        Contract: -1,
        ServerType: 1,
        ip: null,
        servername: "",
        servername_fqdn: ""
    } as IServer
}




export const ServerTable:React.FC<IProps> = ({idCompany, serverTypeArray, serverArray,  setServerArray, serverIdArray, setServerIdArray, contractArray, contractIdArray}) => {
    const isAdmin = getIsAdmin();


    
    
    const [editServer,setEditServer] = useState(getEmptyServer());


    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        {
            headerName: "Rufnummer",
            field: "mobil",
            width: 150
        },
        {
            headerName: "E-Mail",
            field: "email",
            width: 150
        },
        {
            headerName: "Vorname",
            field: "first_name",
            flex: 1
        },
        {
            headerName: "Nachname",
            field: "last_name",
            flex: 1
        }

    ]

    const tableCol:IObjectTableCol[] = [
        {
            title: "Nr.",
            attr: "id"
        },
        {
            title: "Rufnummer",
            attr: "mobil",
            width: 150
        },
        {
            title: "E-Mail",
            attr: "email",
            width: 150
        },
        {
            title: "Vorname",
            attr: "first_name"
        },
        {
            title: "Nachname",
            attr: "last_name"
        }

    ]


    return(
        <>
            <ObjectTable
                columns={columns}
                objectTableCol={tableCol}
                title="DiNo-Instanzen"
                objectArray={serverArray}
                selectedId={serverIdArray}
                setSelectedId={setServerIdArray}
                //
                setObjectArray={setServerArray}
                editObject={editServer}
                setEditObject={setEditServer}
                getNewObject={getEmptyServer}
                uploadUrl="/server/"
                disableBtns={!isAdmin}
            >
                <ServerEdit
                     editServer={editServer}
                     setEditServer={setEditServer}
                     serverTypeArray={serverTypeArray}
                     contractArray={
                        contractArray
                            .filter(x => contractIdArray.indexOf(x.id) > -1)
                     }
                />
            </ObjectTable>
        </>
    )
}