import React from "react";
import { IApp } from "../../interfaces/IApp";
import { Grid } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";


interface IProps {
    editApp: IApp;
    setEditApp: Function;
}



export const AppEdit:React.FC<IProps> = ({editApp,setEditApp}) => {
    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={8}>
                    <CustomeTextField
                        label="Bezeichnung"
                        attr="app_name"
                        type="string"
                        object={editApp}
                        setObject={setEditApp}
                        required
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="System-Schlüssel"
                        attr="system_key"
                        type="string"
                        object={editApp}
                        setObject={setEditApp}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Preis"
                        attr="selling_price"
                        type="number"
                        object={editApp}
                        setObject={setEditApp}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Hervorheben im Marketplace"
                        attr="isHighlighted"
                        type="boolean"
                        object={editApp}
                        setObject={setEditApp}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Beschreibung"
                        attr="description"
                        type="string"
                        object={editApp}
                        setObject={setEditApp}
                        rows={5}
                    />
                </Grid>
            </Grid>

        </>
    )
}