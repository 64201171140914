import { useState,useEffect } from "react";

const session_token = localStorage.getItem('token');

export function deleteFetch(endpoint:string, idToDelete:number | number[], setWasSuccessfullyDeleted:Function) {
    // Settings 
    const requestOptions = {
        method: 'DELETE',
        headers : { "Authorization" : "Token " + session_token }
    };
    var endpoint = `/api/${endpoint}`

    if (Array.isArray(idToDelete)) {
        idToDelete.map(x => endpoint = endpoint + `/${x}`)
    } else {
        endpoint = endpoint +  `/${idToDelete}`
    }


    fetch(endpoint, requestOptions)
    .then(res => {
        if (res.status === 200) { setWasSuccessfullyDeleted(true);  setWasSuccessfullyDeleted(true);}
        else {throw Error(`ERROR DELETE in ${endpoint}: ${res.status}`)}
    })
    .catch((error) => {
        console.error(error);
        setWasSuccessfullyDeleted(false);
    })
}

export function uploadFetch(
    endpoint:string,
    isNew:boolean, 
    uploadlObject:any, 
    setFunction:Function, 
    setWasSuccessfullyUploaded?: Function, 
    wasSaved?:Function,
    setIsLoading?: Function
) {
    (setIsLoading) && setIsLoading(true);
    // Settings 
    const requestOptions = {
        method: (isNew) ? 'POST' : 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization" : "Token " + session_token },
        body: JSON.stringify(uploadlObject)
    };

    fetch(`/api${endpoint}`, requestOptions)
    .then(res => {
        if (res.status === 200) { return res.json()}
        else {throw Error(`ERROR ${(isNew) ? 'POST' : 'PUT'} in ${endpoint}: ${res.status}`)}
    })
    .then(res => {
        (setWasSuccessfullyUploaded) && setWasSuccessfullyUploaded(true);
        (wasSaved) && wasSaved(true);
        (setIsLoading) && setIsLoading(false);
        setFunction(res);
    })
    .catch((error) => {
        console.error(error);
        (setWasSuccessfullyUploaded) && setWasSuccessfullyUploaded(false);
        (wasSaved) && wasSaved(true);
        (setIsLoading) && setIsLoading(false);
    })
}

export function getFetch<S>(endpoint:string,idToGet:number|string|undefined|null,setFunction?:Function,setWasSuccessfully?:Function) { 
    let targetEndpoint = `/api${endpoint}`;
    let tryFetch = true;

    if (endpoint.includes("/") && !endpoint.includes("?") && idToGet) {
        targetEndpoint = targetEndpoint + `${idToGet}`
        
        tryFetch = (idToGet !== undefined)
    }

    const requestOptions = {
        headers: { "Authorization" : "Token " + session_token },
    };


    if (!tryFetch) {
        return null;
    } else {
        fetch(targetEndpoint,requestOptions)
        .then(res => {
            if (res.status === 200) { return res.json()}
            else if (res.status === 204) { return null}
            else {throw Error(`ERROR GET in ${endpoint}: ${res.status}`)}
        })
        .then(res => {
            (setFunction !== undefined) && setFunction(res);
            (setWasSuccessfully !== undefined) && setWasSuccessfully(true);
        })
        .catch((error) => {
            console.error(error);
            (setWasSuccessfully !== undefined) && setWasSuccessfully(false);
        })
    }
}

export function useFetch<S>(endpoint:string,targetId: number | string| null | undefined = undefined) : [S | undefined,Function,number | null] {
    const [responseValue,setResponseValue] = useState<S>();
    const [statusCode,setStatusCode] = useState<number|null>(null);


    const requestOptions = {
        headers: { "Authorization" : "Token " + session_token },
    };

    useEffect(() => {
        let targetEndpoint = `/api${endpoint}`;
        let execute = true;
    
        
        if (targetId !== undefined) {
            if (targetId === null || targetId === -1) {
                execute = false;
            } else {
                targetEndpoint = targetEndpoint + `${targetId}`;
            }
        }

        if (execute) {
            fetch(targetEndpoint,requestOptions)
            .then(res => {
                setStatusCode(res.status);
                if (res.status === 200) { return res.json()}
                else if (res.status === 204) { return null}
                else {throw Error(`ERROR GET in ${endpoint}: ${res.status}`)}
            })
            .then(res => {
                setResponseValue(res);
            })
            .catch((error) => {
                console.error(error);
            })
        }

    },[])


    return [
        responseValue,
        setResponseValue,
        statusCode
    ]
}