import React, { useState } from "react";
import { IServer } from "../interfaces/IServer";
import { GridColDef } from "@mui/x-data-grid";
import { GenericOverview } from "../components/core/GenericOverview";
import { ServerEdit } from "../components/Server/ServerEdit";
import { useFetch } from "../hooks/useFetch";
import { IServerType } from "../interfaces/IServerType";
import { IContract } from "../interfaces/IContract";



const getEmptyServer = () => {
    return {
        id: -1,
        servername: "",
        api_key: "",
        ip: "",
        servername_fqdn: ""
    } as IServer
} 


export const Servers:React.FC = () => {
    //
    const [currentArray, setCurrentArray] = useState<IServer[]>([]);
    const [editServer,setEditServer] = useState(getEmptyServer());
    //
    const [serverArray, setServerArray, statusCodeServer] = useFetch<IServer[]>("/server/");
    const [serverTypeArray, setServerTypeArray, statusCodeServerType] = useFetch<IServerType[]>("/server/type/");
    //
    const [contractArray, setContractArray, statusCodeContract] = useFetch<IContract[]>("/company/contract/");

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },    
        { 
            field: 'ip', 
            headerName: 'IP', 
            width: 200
        },    
        { 
            field: 'servername', 
            headerName: 'Server', 
            flex: 1
        },   
        { 
            field: 'servername_fqdn', 
            headerName: 'FQDN', 
            flex: 1
        },  
    ]

    return(
        <GenericOverview
            title="Server"
            btnTitle="Neuer Server"
            columns={columns}
            editObject={editServer}
            setEditObject={setEditServer}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/server/"
            getEmptyObject={getEmptyServer}
            enableDialog
            dialogWidth="md"

        >
            <ServerEdit
                editServer={editServer}
                setEditServer={setEditServer}
                serverTypeArray={serverTypeArray || []}
                contractArray={contractArray || []}
            />
        </GenericOverview>
    )
}