import { Close } from "@mui/icons-material";
import { AppBar, Box, Button, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomEditor } from "./CustomEditor";
import { TransitionProps } from "@mui/material/transitions";
import { CustomeTextField } from "./CustomeTextField";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  





interface IProps {
    btnTitle: string;
    txt: string;
    setTxt: Function;
    disableEdit?: boolean;
}



export const HTMLEditor:React.FC<IProps> = ({btnTitle, txt, setTxt, disableEdit}) => {
    const [isOpenEditor,setIsOpenEditor] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(disableEdit === undefined || disableEdit === false);

    return(
        <>
            <Dialog
                fullScreen
                open={isOpenEditor}
                onClose={() => setIsOpenEditor(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setIsOpenEditor(false)}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">HTML-Editor: {btnTitle}</Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{m: 5}}>
                    <Grid container spacing={2}>
                        {(isEditEnabled) &&
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="HTML"
                                    attr="txt"
                                    type="string"
                                    object={{txt: txt}}
                                    setObject={(x:{txt: string}) => setTxt(x.txt)}
                                    rows={10}
                                />
                            </Grid>
                        }


                        <Grid item sm={isEditEnabled ? 6 : 12}>
                            <div dangerouslySetInnerHTML={{ __html: txt }} />
                        </Grid>
                    </Grid>
                </Box>

            </Dialog>

            <Button variant="outlined" fullWidth onClick={() => setIsOpenEditor(true)}>{btnTitle}</Button>
        </>
    )
}