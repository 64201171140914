import React from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { NavBar } from "./NavBar";
import { AppOverview } from "../../sites/extension/AppOverview";
import { ModuleOverview } from "../../sites/extension/ModuleOverview";
import { Subscriptions } from "../../sites/Subscriptions";
import { Customers } from "../../sites/Customers";
import { Contracts } from "../../sites/Contracts";
import { SystemMailOverview } from "../SystemMail/SystemMailOverview";
import { Websites } from "../../sites/Websites";
import { Servers } from "../../sites/Servers";
import { Clients } from "../../sites/Clients";


export const PageRouter:React.FC = () => {
    /**
     * Für Redux siehe private Frontends
     */

    return(
        <>
            <BrowserRouter>
                <NavBar>
                    <Routes>
                        <Route path="/" element={<Contracts/>} />

                        <Route path="/customers" element={<Customers/>} />
                        <Route path="/contracts" element={<Contracts/>} />
                        <Route path="/websites" element={<Websites/>} />
                        <Route path="/dino" element={<Clients/>} />

                        <Route path="/extension/apps" element={<AppOverview/>} />
                        <Route path="/extension/modules" element={<ModuleOverview/>} />
                        <Route path="/subscriptions" element={<Subscriptions/>} />

                        <Route path="/systemmail" element={<SystemMailOverview/>} />
                        <Route path="/servers" element={<Servers/>} />
                    </Routes>
                </NavBar>
            </BrowserRouter>
        </>
    )
}