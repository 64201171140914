import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, IconButton } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getIsAdmin } from "../../core/helper";
import { ArrowUpward, Delete } from "@mui/icons-material";
import { CustomEditor } from "../../core/CustomEditor";
import { IWebsiteSubsite } from "../../../interfaces/IWebsiteSubsite";

interface IProps {
    websiteSubsiteObect: IWebsiteSubsite;
    websiteSubsiteArray: IWebsiteSubsite[];
    setWebsiteSubsiteArray: Function;
}


export const WebsiteSubsiteEntry:React.FC<IProps> = ({websiteSubsiteObect, websiteSubsiteArray, setWebsiteSubsiteArray}) => {
    const isAdmin = getIsAdmin();

    const [editWebsiteSubsiteObect,setEditWebsiteSubsiteObect] = useState(websiteSubsiteObect);

    const setText = (txt: string) => {
        setEditWebsiteSubsiteObect({... editWebsiteSubsiteObect, body : txt});
    }

    const handleRemoveMe = () => {
        setWebsiteSubsiteArray([
            ...websiteSubsiteArray
                .filter(x => x.id !== websiteSubsiteObect.id)
                .map(x => x.position > websiteSubsiteObect.position ? {...x, position: x.position -1} : x )
        ])
    }


    useEffect(() => {
        setWebsiteSubsiteArray([
            ...websiteSubsiteArray.map(x => x.id === editWebsiteSubsiteObect.id ? editWebsiteSubsiteObect : x)
        ])
    },[editWebsiteSubsiteObect])



    return(
        <Box sx={{mt: 5}}>  
            <Card variant="outlined">
                <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={11}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Titel der Unterseite"
                                    attr="subsite_title"
                                    type="string"
                                    object={editWebsiteSubsiteObect}
                                    setObject={setEditWebsiteSubsiteObect}
                                    disabled={!isAdmin && editWebsiteSubsiteObect.id > 0}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Im Footer anzeigen"
                                    attr="isEnableFooter"
                                    type="boolean"
                                    object={editWebsiteSubsiteObect}
                                    setObject={setEditWebsiteSubsiteObect}
                                    disabled={!isAdmin && editWebsiteSubsiteObect.id > 0}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Im Menü anzeigen"
                                    attr="isEnableMenu"
                                    type="boolean"
                                    object={editWebsiteSubsiteObect}
                                    setObject={setEditWebsiteSubsiteObect}
                                    disabled={!isAdmin && editWebsiteSubsiteObect.id > 0}
                                />
                            </Grid>


                            <CustomEditor txt={editWebsiteSubsiteObect.body} setTxt={setText}/>
                        </Grid>
                    </Grid>
                    <Grid item sm={1}>
                        <IconButton disabled={!isAdmin} onClick={handleRemoveMe}><Delete/></IconButton>
                    </Grid>
                </Grid>

                </CardContent>
            </Card>
        </Box>
    )
}