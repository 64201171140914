import React, { useEffect, useState } from "react";
import { IApp } from "../../interfaces/IApp";
import { IModule } from "../../interfaces/IModule";
import { IScheduling } from "../../interfaces/IScheduling";
import { ISubscription } from "../../interfaces/ISubscription";
import { IContract } from "../../interfaces/IContract";
import { Box, Collapse, Grid, MenuItem, Tab, Tabs } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { getIsAdmin } from "../core/helper";
import { CustomTabPanel, a11yProps } from "../core/CustomTabPanel";
import { ModuleTable } from "../Subscription/Childs/ModuleTable";
import { AppTable } from "../Subscription/Childs/AppTable";


interface IProps {
    editContract: IContract;
    setEditContract: Function;
    appArray: IApp[];
    moduleArray: IModule[];
    schedulingArray: IScheduling[];
    subscriptionArray: ISubscription[];
}



export const ContractEdit:React.FC<IProps> = ({editContract, setEditContract, appArray, moduleArray, schedulingArray, subscriptionArray}) => {
    const isAdmin = getIsAdmin();
    //
    const [orgContract,setOrgContract] = useState(editContract);
    //
    const [currentPage,setCurrentPage] = useState(0);
    //
    const [assignedApps,setAssignedApps] = useState(editContract.Assigned_Apps);
    const [assignedModule,setAssignedModule] = useState(editContract.Assigned_Module);

    const handleChangeSubscription = (changedContract:IContract) => {
        let testSubscription = subscriptionArray.find(x => x.id === changedContract.Subscription);

        if (editContract.Subscription !== null && changedContract.Subscription === null) {
            setEditContract({
                ...editContract,
                Assigned_Apps: [],
                Assigned_Module: [],
                selling_price: 0,
                isFreeAll: false,
                Scheduling: 1,
                contract_title: "",
                Subscription: null
            } as IContract)
            setAssignedApps([]);
            setAssignedModule([]);
        }
        else if (testSubscription !== undefined) {
            setEditContract({
                ...editContract,
                Assigned_Apps: testSubscription.Assigned_Apps,
                Assigned_Module: testSubscription.Assigned_Module,
                selling_price: testSubscription.selling_price,
                isFreeAll: testSubscription.isFreeAll,
                Scheduling: testSubscription.Scheduling,
                contract_title:`Vertrag: ${testSubscription.subscription_name}`,
                Subscription: testSubscription.id
            } as IContract)
            setAssignedApps(testSubscription.Assigned_Apps);
            setAssignedModule(testSubscription.Assigned_Module);
        }
    }


    useEffect(() => {
        let selling_price = Number(editContract.selling_price);

        if (editContract.Subscription === null) {
            if (appArray.length > 0 && editContract.Assigned_Apps !== assignedApps) {
                selling_price += appArray
                    .filter(x => assignedApps.indexOf(x.id) > -1)
                    .map(x => Number(x.selling_price))
                    .reduce((a,b) => a+b,0)
            }

            if (moduleArray.length > 0 && editContract.Assigned_Module !== assignedModule) {
                selling_price += moduleArray
                    .filter(x => assignedModule.indexOf(x.id) > -1)
                    .map(x => Number(x.selling_price))
                    .reduce((a,b) => a+b,0)
            }
        }

        setEditContract({
            ...editContract,
            Assigned_Apps: assignedApps,
            Assigned_Module: assignedModule,
            selling_price: selling_price
        } as IContract)
    },[assignedApps,assignedModule])


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentPage(newValue);
    };


    return(
        <>
            <Collapse in={editContract.id > 0}  sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <b>Vertragsnummer</b>
                    </Grid>
                    <Grid item sm={8}>
                        {editContract.contract_number}
                    </Grid>

                    <Grid item sm={4}>
                        <b>Lizenz</b>
                    </Grid>
                    <Grid item sm={8}>
                        {editContract.license_key}
                    </Grid>
                </Grid>
            </Collapse>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Abonnement"
                        attr="contract_title"
                        type="string"
                        object={editContract}
                        setObject={setEditContract}
                        required
                        disabled={editContract.id > 0}
                    />
                </Grid>


                <Grid item sm={6}>
                    <CustomeTextField
                        label="Paket"
                        attr="Scheduling"
                        type="select"
                        object={editContract}
                        setObject={setEditContract}
                        disabled={editContract.id > 0}
                    >
                        {schedulingArray.map(x =>
                            <MenuItem key={`select-scheduling-${x.id}`} value={x.id}>
                                {x.scheduling}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Paket"
                        attr="Subscription"
                        type="select"
                        object={editContract}
                        setObject={handleChangeSubscription}
                        disabled={editContract.id > 0}
                        null
                    >
                        {subscriptionArray.map(x =>
                            <MenuItem key={`select-subscription-${x.id}`} value={x.id}>
                                {x.subscription_name}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>


                <Grid item sm={4}>
                    <CustomeTextField
                        label="Alles inklusive"
                        attr="isFreeAll"
                        type="boolean"
                        object={editContract}
                        setObject={setEditContract}
                        required
                        disabled={editContract.id > 0}
                    />
                </Grid>

                <Grid item sm={8}>
                    <CustomeTextField
                        label="Preis"
                        attr="selling_price"
                        type="number"
                        object={editContract}
                        setObject={setEditContract}
                        required
                        disabled={editContract.id > 0}
                    />
                </Grid>

                <Grid item sm={4}>
                    <CustomeTextField
                        label="Unterschrieben"
                        attr="signed_at"
                        type="date"
                        object={editContract}
                        setObject={setEditContract}
                        required
                        disabled={editContract.id > 0}
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="Vertrag ab"
                        attr="active_at"
                        type="date"
                        object={editContract}
                        setObject={setEditContract}
                        required
                        disabled={editContract.id > 0}
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="Gekündigt"
                        attr="closed_at"
                        type="date"
                        object={editContract}
                        setObject={setEditContract}
                        disabled={!isAdmin || orgContract.closed_at !== null}
                    />
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', mt: 5 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentPage} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Module" {...a11yProps(0)} />
                        <Tab label="Apps" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={currentPage} index={0}>
                    <ModuleTable
                        editSubscription={editContract}
                        moduleArray={moduleArray}
                        assignedModule={assignedModule}
                        setAssignedModule={setAssignedModule}
                        disableBtn={editContract.id > 0}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={currentPage} index={1}>
                    <AppTable
                        editSubscription={editContract}
                        appArray={appArray}
                        assignedApps={assignedApps}
                        setAssignedApps={setAssignedApps}
                        disableBtn={editContract.id > 0}
                    />
                </CustomTabPanel>
            </Box>
        </>
    )
}