import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { SecurityDriver } from './components/core/SecurityDriver';
import { PageRouter } from './components/core/PageRouter';
import { deDE } from '@mui/x-data-grid/locales';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#000',
        light: '#19F6D4',
        contrastText: '#fff',
      },
      secondary: {
        main: '#cfa128',
      },
      
      /*
      text: {
        primary: '#30919A',
      },
      */
    },
    
  },
  deDE // x-data-grid translations
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SecurityDriver>
          <PageRouter/>
      </SecurityDriver>
    </ThemeProvider>
  );
}

export default App;
