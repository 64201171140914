import React, {useEffect, useState} from "react";
import {Alert, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Box} from "@mui/system";
import {CustomEditor} from "../core/CustomEditor";
import { ISystemMail } from "./ISystemMail";
import { uploadFetch, useFetch } from "../../hooks/useFetch";

interface IProps {
    currentId: number;
    setCurrentId: Function;
    currentArray: ISystemMail[];
    setCurrentArray: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
}

export const SystemMailEdit: React.FC<IProps> = (props) => {
    const [systemMailOrg, setSystemMailOrg] = useState(props.currentArray.find(x => x.id === props.currentId));
    const [systemMailObject, setSystemMailObject] = useState(systemMailOrg);



    const setText = (txt: string) => {
        //console.log("INSIDE");
        //console.log(txt);
        setSystemMailObject({... systemMailObject!, Body : txt});
        /*
        console.log("INSIDE");
        console.log(txt);
        setSettingObject({
            ...settingObject,
            SettingValue: txt
        })
        if(settingObject?.SettingValue !== undefined && systemMailObject !== undefined)
        {
            setSystemMailObject({... systemMailObject, Body : settingObject.SettingValue})
        }
        */
    }


    const isDisabled = () => {
        if (systemMailObject !== undefined) {
            return (
                systemMailObject.Title === ""
                || systemMailObject.Subject === ""
                || systemMailObject.Body === ""
            )
        } else {
            return true;
        }
    }

    const wrapperSaved = (localObject: ISystemMail) => {
        if (props.currentId < 0) {
            props.setCurrentArray([
                ...props.currentArray,
                localObject
            ])
        } else {
            props.setCurrentArray([
                ...props.currentArray.map(x => x.id === localObject.id ? localObject : x)
            ])
        }
        props.setCurrentId(null);
    }


    const handleSave = () => {
        if (systemMailObject !== undefined) {
            let uploadObeject: ISystemMail = {
                ...systemMailObject,
            }
            uploadFetch(`/systemmail/${(uploadObeject.id > 0) && uploadObeject.id }`,
                (uploadObeject.id < 0),
                uploadObeject,
                wrapperSaved,
                props.setWasSavedSuccesfully,
                props.setWasSaved
            );
        }
    }

    if (systemMailOrg === undefined || systemMailObject === undefined) {
        return <Alert severity="error">Fehler!</Alert>
    } else {
        return (
            <>
                <Typography variant="h5">
                    {`${systemMailOrg.Title}`}
                </Typography>

                <Box sx={{mt: 2}}/>

                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField
                            label="Titel (nur intern)"
                            size="small"
                            value={systemMailObject.Title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, Title: event.target.value})}
                            error={systemMailObject.Title === ""}
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            label="Betreff"
                            size="small"
                            value={systemMailObject.Subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, Subject: event.target.value})}
                            error={systemMailObject.Subject === ""}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Box sx={{mt: 5}}/>

                <Grid container spacing={2}>
                    <CustomEditor txt={systemMailObject.Body} setTxt={setText}/>
                </Grid>


                <Box sx={{mt: 2}}/>
                <Button onClick={handleSave} sx={{float: "right", ml: 2}} variant="contained"
                        disabled={isDisabled()}>Speichern</Button>
                <Button onClick={() => props.setCurrentId(null)} sx={{float: "right"}}
                        variant="outlined">Abbruch</Button>
            </>
        )
    }

}