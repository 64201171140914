import React, { useEffect, useState } from "react";
import { IWebsite } from "../../../interfaces/IWebsite";
import { IWebVersion, IWebVersionFullVersion } from "../../../interfaces/IWebVersion";
import { getFetch, uploadFetch, useFetch } from "../../../hooks/useFetch";
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material";
import { CustomTabPanel, a11yProps } from "../../core/CustomTabPanel";
import { IWebsiteText } from "../../../interfaces/IWebsiteText";
import { IWebsitePerson } from "../../../interfaces/IWebsitePerson";
import { IWebsiteSubsite } from "../../../interfaces/IWebsiteSubsite";
import { WebsiteTextEntry } from "./WebsiteTextEntry";
import { WebsiteSubsiteEntry } from "./WebsiteSubsiteEntry";
import { ICustomer } from "../../../interfaces/ICustomer";
import { WebsitePersonEntry } from "./WebsitePersonEntry";
import { IMIMEtype } from "../../../interfaces/IMIMEtype";
import { IWebsitePicture } from "../../../interfaces/IWebsitePicture";
import { WebsitePictureEntry } from "./WebsitePictureEntry";


interface IProps {
    website: IWebsite;
    idWebsiteVersion: number;
    websiteVersionArray: IWebVersion[];
    setWebsiteVersionArray: Function;
    setIsOpen: Function;
    personArray: ICustomer[];
    mimeTypeArray: IMIMEtype[];
}



const getEmptyWebVersion = (idWebsite:number) => {
    return {
        id: -1,
        Website: idWebsite,
        version: 1,
        published_at: null,
        WebsitePersons: [],
        WebsiteSubsite: [],
        WebsiteTexts: [],
        WebsitePictures: []
    } as IWebVersionFullVersion
}



export const WebsiteVersionEdit:React.FC<IProps> = ({website, idWebsiteVersion, websiteVersionArray, setWebsiteVersionArray, setIsOpen, personArray, mimeTypeArray }) => {
    const [oldWebVersion,setOldWebVersion] = useFetch<IWebVersionFullVersion>("/website/version/",idWebsiteVersion);
    
    const [editWebVersion,setEditWebVersion] = useState(getEmptyWebVersion(website.id));
    const [textArray,setTextArray] = useState<IWebsiteText[]>([]);
    const [webPersonArray,setWebPersonArray] = useState<IWebsitePerson[]>([]);
    const [subsiteArray,setSubsiteArray] = useState<IWebsiteSubsite[]>([]);
    const [pictureArray,setPictureArray] = useState<IWebsitePicture[]>([]);
    //
    const [isOpenDialogPublish,setIsOpenDialogPublish] = useState(false)
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(true);
    
    const [currentPage, setCurrentPage] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentPage(newValue);
    };


    const handleAddText = () => {
        let position = 1;
        let tmpId = -1;

        if (textArray.length > 0) {
            position = Math.max(...textArray.map(x => x.position)) + 1;
            let tmpIdArray = Math.min(...textArray.map(x => x.id)) - 1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray - 1;
            }
        }

        let newObject:IWebsiteText = {
            id: tmpId,
            position: position,
            system_key: "",
            intern_title: "",
            text: "",
            WebsiteVersion: idWebsiteVersion
        }
        setTextArray([
            ...textArray,
            newObject
        ])
    }

    const handleAddSubsite = () => {
        let position = 1;
        let tmpId = -1;

        if (subsiteArray.length > 0) {
            position = Math.max(...subsiteArray.map(x => x.position)) + 1;
            let tmpIdArray = Math.min(...subsiteArray.map(x => x.id)) - 1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray - 1;
            }
        }

        let newObject:IWebsiteSubsite = {
            id: tmpId,
            position: position,
            subsite_title: "",
            body: "",
            WebsiteVersion: idWebsiteVersion,
            isEnableFooter: false,
            isEnableMenu: false
        }
        setSubsiteArray([
            ...subsiteArray,
            newObject
        ])
    }

    const handleAddPerson = () => {
        let position = 1;
        let tmpId = -1;

        if (webPersonArray.length > 0) {
            position = Math.max(...webPersonArray.map(x => x.position)) + 1;
            let tmpIdArray = Math.min(...webPersonArray.map(x => x.id)) - 1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray - 1;
            }
        }

        let newObject:IWebsitePerson = {
            id: tmpId,
            position: position,
            WebsiteVersion: idWebsiteVersion,
            long_description: "",
            Person: null,
            small_description: ""
        }
        setWebPersonArray([
            ...webPersonArray,
            newObject
        ])
    }

    const handleAddPicture = () => {
        let position = 1;
        let tmpId = -1;

        if (pictureArray.length > 0) {
            let tmpIdArray = Math.min(...pictureArray.map(x => x.id)) - 1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray - 1;
            }
        }

        let newObject:IWebsitePicture = {
            id: tmpId,
            MIMEtype: 1,
            WebsiteVersion: idWebsiteVersion,
            picture: "",
            file_name: ""
        }
        setPictureArray([
            ...pictureArray,
            newObject
        ])
    }


    useEffect(() => {
        if (oldWebVersion !== undefined) {
            setEditWebVersion({...oldWebVersion, version: oldWebVersion.version + 1})
            setTextArray(oldWebVersion.WebsiteTexts);
            setWebPersonArray(oldWebVersion.WebsitePersons);
            setSubsiteArray(oldWebVersion.WebsiteSubsite);
            setPictureArray(oldWebVersion.WebsitePictures)
        }
    },[oldWebVersion])


    const handleAfterSave = (res:IWebVersionFullVersion) => {
        setWebsiteVersionArray([
            res,
            ...websiteVersionArray
        ])
        setOldWebVersion(res);

        if (website.ServerInstance !== null) {
            setIsOpenDialogPublish(true);
        }
        else {
            setIsOpen(false);
        }
    }


    const handleSave = () => {
        setWasSuccessfullySaved(true);
        let uploadObject:IWebVersionFullVersion = {
            ...editWebVersion,
            Website: website.id,
            WebsiteTexts: textArray,
            WebsitePersons: webPersonArray,
            WebsiteSubsite: subsiteArray,
            WebsitePictures: pictureArray
        }
        uploadFetch(`/website/version/${oldWebVersion?.id}`,true,uploadObject,handleAfterSave,setWasSuccessfullySaved,() => {},setIsLoading)
    }

    const handleAfterPublic = () => {
        setIsOpen(false);
        setIsLoading(false);
    }

    const handlePulic = () => {
        setIsLoading(true);
        getFetch(`/website/publish/`,oldWebVersion?.id, handleAfterPublic,() => {});
    }


    if (oldWebVersion === undefined) { return <CircularProgress/> }
    else {
        return(
            <>
                <Dialog open={isLoading}>
                    <DialogContent>
                        <CircularProgress/>
                    </DialogContent>
                </Dialog>

                <Dialog open={isOpenDialogPublish}>
                    <DialogTitle>Publizieren</DialogTitle>
                    <DialogContent>
                        Wollen Sie Ihre Änderungen publizieren und somit öffentlich machen?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined"sx={{float: "right"}} onClick={handlePulic}>Publizieren</Button>        
                        <Button variant="contained" sx={{float: "right"}} onClick={() => setIsOpen(false)}>Nein</Button>        
                    </DialogActions>
                </Dialog>
                
                <Button variant="contained" sx={{float: "right"}} onClick={handleSave}>Speichern</Button>
                <Button variant="outlined" disabled={website.ServerInstance === null} sx={{float: "right", mr: 2}} onClick={handlePulic}>Publizieren</Button>
                <br/>
                <Collapse sx={{mt: 5}} in={!wasSuccessfullySaved}>
                    <Alert severity="warning">
                        Das Speichern war nicht erfolgreich.
                        <br/>Sind alle Pflichtfelder gesetzt?
                    </Alert>
                </Collapse>

                <Box sx={{ width: '100%', mt: 5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentPage} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Texte" {...a11yProps(0)} />
                            <Tab label="Personen" {...a11yProps(1)} />
                            <Tab label="Unterseiten" {...a11yProps(2)} />
                            <Tab label="Bilder" {...a11yProps(3)} />
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={currentPage} index={0}>
                        <Box sx={{mt: 2}}>
                            <Button sx={{float: "right"}} variant="outlined" onClick={handleAddText}>Text hinzufügen</Button>
                            <br/>
                            <Box sx={{mt: 5}} />  
                            {textArray.sort((a,b) => a.position > b.position ? 1 : -1).map((websiteText,idx) =>
                                <WebsiteTextEntry 
                                    key={`text-${websiteText.id}-${idx}`}
                                    websiteText={websiteText}
                                    websiteTextArray={textArray}
                                    setWebsiteTextArray={setTextArray}
                                />
                            )}
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={currentPage} index={1}>
                        <Box sx={{mt: 2}}>
                            <Button sx={{float: "right"}} variant="outlined" onClick={handleAddPerson}>Person hinzufügen</Button>
                            <br/>
                            <Box sx={{mt: 5}} />  
                            {webPersonArray.sort((a,b) => a.position > b.position ? 1 : -1).map((person,idx) =>
                                <WebsitePersonEntry 
                                    key={`person-${person.id}-${idx}`}
                                    webPerson={person}
                                    webPersonArray={webPersonArray}
                                    setWebPersonArray={setWebPersonArray}
                                    personArray={personArray}
                                />
                            )}
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={currentPage} index={2}>
                        <Box sx={{mt: 2}}>
                            <Button sx={{float: "right"}} variant="outlined" onClick={handleAddSubsite}>Unterseite hinzufügen</Button>
                            <br/>
                            <Box sx={{mt: 5}} />  
                            {subsiteArray.sort((a,b) => a.position > b.position ? 1 : -1).map((subsite,idx) =>
                                <WebsiteSubsiteEntry 
                                    key={`subsite-${subsite.id}-${idx}`}
                                    websiteSubsiteObect={subsite}
                                    websiteSubsiteArray={subsiteArray}
                                    setWebsiteSubsiteArray={setSubsiteArray}
                                />
                            )}
                        </Box>
                    </CustomTabPanel>


                    <CustomTabPanel value={currentPage} index={3}>
                        <Box sx={{mt: 2}}>
                            <Alert severity="info" sx={{mb: 2}}>
                                Die Bilder werden bei der Zielseite im Wurzelverzeichnis (/) unter deren Name abgespeichert.
                            </Alert>
                            <Button sx={{float: "right"}} variant="outlined" onClick={handleAddPicture}>Bild hinzufügen</Button>
                            <br/>
                            <Box sx={{mt: 5}} />  
                            {pictureArray.sort((a,b) => a.file_name > b.file_name ? 1 : -1).map((picture,idx) =>
                                <WebsitePictureEntry 
                                    key={`picture-${picture.id}-${idx}`}
                                    picture={picture}
                                    pictureArray={pictureArray}
                                    setPictureArray={setPictureArray}
                                    mimeTypeArray={mimeTypeArray}
                                />
                            )}
                        </Box>
                    </CustomTabPanel>
                </Box>

                {/*JSON.stringify(webPersonArray)*/}
            </>
        )
    }

}